import logo from './logo.svg';
import './App.css';
import EmployeeComponent from "./component/EmployeeComponent";

function App() {
  return (
    <div className="container">
      <EmployeeComponent />
    </div>
  );
}

export default App;
